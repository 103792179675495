<template>
  <div>
    <div class="leasingAgreement">
      <h3>测绘玩家免押金协议 </h3>
      <p>
        《测绘玩家免押金协议》(以下简称“本协议”)由测绘玩家(以下简称“本公司”)和您签订，本协议约定了本公司向您提供免缴纳押金的服务(以下简称“本服务”、“本协议服务’)前提、双方权利义务及其它事项。您在本公司的网站或/和平台(公众号)
        上点击以合理的理解表明您希望与本公司签订本协议的选项,或您接受、使用本服务的行为，即表明您同意并与本公司签订本协议，本协议在您与本公司之间成立并生效。</p>
      <h4>声明与承诺</h4>
      <p>1、本公司一贯重视消费者权益保护，故本协议已对本公司认为的与您的权益有或可能有重大关系的条款及对本公司具有或可能具有免责或限制责任的条款用粗体字予以标注，请您务必注意。
      </p>
      <p>
        2、您理解并承诺:在您接受、使用本协议服务前，您已充分阅读、理解并接受本协议的全部内容，您使用本协议服务，即表示您同意遵循本协议之所有约定。
      </p>
      <p> 3、作为本协议服务的提供方，本公司有权根据情况的需要对本协议内容进行变更，变更的原因包括但不限于国家法律、法规及其他规范性文件的规定变化，增加、变更了新的服务类型或服务方式，进一步明确协议双方的权利义务。
      </p>
      <p>
        4、因本协议所涉服务的性质独特及服务用户数量众多，故本公司在对本协议内容进行变更时，不会另行单独通知您，该等变更只会以在本公司网站或/和平台(公众号)
        或其他适当的地方公告的方式予以公布;您在本协议内容公告变更后继续使用服务，表示您已充分阅读、理解并接受变更后的协议内容，并遵循变更后的协议内容而使用本服务;若您不同意修改后的协议内容，您应停止使用本服务。
        修改后的协议内容，您应停止使用本服务。
      </p>
      <p>
        5、您保证:作为自然人，在您同意接受本协议并
        注册成为本公司用户时，您已经年满16周岁;
        作为企业、事业单位等组织，您在中华人民
        共和国(“中国”)大陆地区(不含香港、台湾、
        澳门地区)合法开展经营活动或其他业务,或
        依照中国大陆地区(不含香港、台湾、澳门地
        区)法律登记注册成立;本协议内容不受您所属
        国家或博区法律的约束不目各前沭各件
      </p>
    </div>
    <div class="btn">
      <div @click="goBack">
        我已阅读并同意
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "leasingAgreement",
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.leasingAgreement{
  height: 100%;
  background: #F6F6F6;
  padding: 0.28rem 0.3rem 2rem 0.33rem;
  h3{
    font-size: 0.42rem;
    font-family: xiaocheng;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 0.53rem;
  }
  h4{
    font-size: 0.39rem;
    font-family: xiaocheng;
    font-weight: bold;
    color: #333333;
    margin:0.05rem 0rem ;
  }
  p{
    font-size: 0.39rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #333333;
    line-height: 0.61rem;
    text-align: justify;
    text-indent: 0.3rem;
  }
}
.btn{
  position: fixed;
  z-index: 999;
  bottom: 0px;
  background: #FFFFFF;
  padding: 0.25rem 0rem;
  width: 100%;
  border: 1px solid #FFFFFF;
  div{
    width: 9.75rem;
    height: 1.11rem;
    background: #FB884F;
    border-radius: 1rem;
    font-size: 0.44rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.11rem;
    text-align: center;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
</style>
